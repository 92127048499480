/* 
   Fonts cannot go into styled-component's createGlobalStyle chunk because when the style tag is re-rendered 
   it causes the font files to re-download and the text elements flicker.
   https://github.com/styled-components/styled-components/issues/2227
*/

@font-face {
  font-family: 'CalibreSchoolWeb';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(./fonts/calibre-r-school-web-bold.woff) format('woff'),
    url(./fonts/calibre-r-school-web-bold.woff2) format('woff2'), 
    url(./fonts/calibre-r-school-web-bold.eot) format('eot');
}

@font-face {
  font-family: 'CalibreSchoolWeb';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(./fonts/calibre-r-school-web-semibold.woff) format('woff'),
    url(./fonts/calibre-r-school-web-semibold.woff2) format('woff2'), 
    url(./fonts/calibre-r-school-web-semibold.eot) format('eot');
}

@font-face {
  font-family: 'CalibreSchoolWeb';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(./fonts/calibre-r-school-web-medium.woff) format('woff'),
    url(./fonts/calibre-r-school-web-medium.woff2) format('woff2'), 
    url(./fonts/calibre-r-school-web-medium.eot) format('eot');
}

@font-face {
  font-family: 'CalibreSchoolWeb';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./fonts/calibre-r-school-web-regular.woff) format('woff'),
    url(./fonts/calibre-r-school-web-regular.woff2) format('woff2'), 
    url(./fonts/calibre-r-school-web-regular.eot) format('eot');
}

@font-face {
  font-family: 'CalibreSchoolWeb';
  font-style: normal;
  font-weight: 300;
  src: url(./fonts/calibre-r-school-web-light.woff) format('woff'),
    url(./fonts/calibre-r-school-web-light.woff2) format('woff2'), 
    url(./fonts/calibre-r-school-web-light.eot) format('eot');
}

@font-face {
  font-family: 'CalibreSchoolWeb';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(./fonts/calibre-r-school-web-thin.woff) format('woff'),
    url(./fonts/calibre-r-school-web-thin.woff2) format('woff2'), 
    url(./fonts/calibre-r-school-web-thin.eot) format('eot');
}

@font-face {
  font-family: 'NSWHandwriting';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(./fonts/Foundation-Regular.woff) format('woff');
}

@font-face {
  font-family: 'NSWHandwriting';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(./fonts/FoundationBold-Regular.woff) format('woff'), 
    url(./fonts/FoundationBold-Regular.eot) format('eot');
}
